$pay-details-border-colour: #eeeeee;

.pay-details ul {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0;
  border: 1px $pay-details-border-colour solid;
  border-radius: 10px;
  overflow: hidden;

  li {
    padding: 15px 25px;
    border-bottom: 1px $pay-details-border-colour solid;

    &:last-child {
      border: 0;
    }

    &.heading {
      background: $pay-details-border-colour;
      font-weight: bold;
    }

    span {
      font-weight: bold;
      display: inline-block;
      min-width: 130px;
    }
  }
}