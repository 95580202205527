@import '../Sass/variables';

$caravan-icon-size: 35px;

.caravan-detail-list {
  list-style: none;
  padding: 0;
  margin: 0;
  
  li {
    padding: 10px;
    border-bottom: 1px $colour-three solid;
    display: flex;
    flex-direction: row;
    align-items: center;
    
    &:first-child {
      border-top: 1px $colour-three solid;
    }
    
    .image-container {
      width: $caravan-icon-size;
      height: $caravan-icon-size;
      margin: 0 15px 0 0;
      text-align: center;
    }
    
    img {
      width: 100%;
      display: block;
      margin: 0 auto;
    }
    
    span {
      font-size: $font-size-two;
    }
  }
}