@import 'variables';

nav {
  display: none;
  background: $colour-one;
  padding: 0;
  margin: 0;
  position: absolute;
  width: 100%;
  z-index: 5;
  top: 76px;
  left: 0;
}

nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
}

.App.open nav {
  display: block;
}

nav a {
  color: white;
  text-decoration: none;
  display: block;
  padding: 20px;
}

nav a:hover, nav a.active {
  background: rgba(0, 0, 0, .5);
}
