@import 'variables';

header {
  background: $colour-one;
  padding: 10px 20px;
  box-shadow: 0 5px 0 0 rgba(0, 0, 0, .1);
  position: relative;
  z-index: 5;

  .container {
    max-width: $site-width;
    margin: 0 auto;
    align-items: center;
    display: flex;
  }

  h1 {
    color: white;
    margin: 0;
    width: 100%;
    font-family: $font-one;
    font-weight: 700;
    font-size: 31px;
    letter-spacing: 3px;
    text-transform: uppercase;

    a {
      text-decoration: none;
      color: white;
    }

    span {
      color: $colour-two;
    }
  }
}

#nav-icon {
  width: 50px;
  height: 36px;
  position: relative;
  margin: 5px 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon span {
  display: block;
  position: absolute;
  height: 5px;
  width: 100%;
  background: #FFF;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon span:nth-child(1) {
  top: 0px;
}

#nav-icon span:nth-child(2),#nav-icon span:nth-child(3) {
  top: 15px;
}

#nav-icon span:nth-child(4) {
  top: 30px;
}

.App.open #nav-icon span:nth-child(1) {
  top: 15px;
  width: 0%;
  left: 50%;
}

.App.open #nav-icon span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.App.open #nav-icon span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.App.open #nav-icon span:nth-child(4) {
  top: 15px;
  width: 0%;
  left: 50%;
}
