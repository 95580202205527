$site-width: 800px;

$font-one: 'Arya', sans-serif;
$font-two: Arial, sans-serif;

$font-size-one: 2.4rem;
$font-size-two: 1.2rem;
$font-size-three: 1.5rem;

$colour-text-one: #333;
$colour-text-two: #888;
$colour-text-three: #00aad4;

$colour-one: #2a2d36;
$colour-two: #ffdf64;
$colour-three: #EEE;

@mixin global-button-main {
  padding: 17px 32px;
  margin: 10px 0;
  border-radius: 7px;
  border: 0;
  background: $colour-two;
  color: $colour-one;

  letter-spacing: 1px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  outline: 0;
  text-decoration: none;
  margin: 10px 0 0 0;
  box-shadow: 0 5px 10px 0px rgba(0, 0, 0, .09);
  text-shadow: 1px 1px 1px 0 white;
  text-shadow: -1px -1px #ffca00, 1px 1px #ffeda6;
  
  &:hover:enabled {
    background: lighten($colour-two, 15%);
  }

  &:active:enabled {
    background: darken($colour-two, 15%);
  }

  &[disabled] {
    opacity: .2;
  }

  @content;
}