@import 'variables';
@import 'responsive';

$app-footer-height: 30px;
$app-footer-top-padding: 15px;

html {
  height: 100%;
}

body {
  margin: 0;
  min-height: 100%;
  padding-bottom: $app-footer-height + $app-footer-top-padding;
  position: relative;
  box-sizing: border-box;
  background: #f7f7f7;
  font-family: -apple-system, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: left;
  color: $colour-text-one;
}

main article {
  max-width: $site-width;
  margin: 0 auto;
  background: white;
  padding: 0 20px 35px;
}

@include responsive-full-width {
  main article {
    padding: 0;
  }
}

$footer-text-color: lighten($colour-one, 50%);

footer {
  position: absolute;
  left: 0; right: 0; bottom: 0;
  padding-top: $app-footer-top-padding;
  height: $app-footer-height;
  background: $colour-one;
  color: $footer-text-color;
  text-align: center;
  font-size: 10px;

  a {
    color: $footer-text-color;
  }
}
