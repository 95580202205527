@import 'variables';
@import 'responsive';

$icon-link-color: lighten($colour-two, 30%);
$icon-link-border: darken($icon-link-color, 20%);

.icon-grid {
  padding: 0 0 20px 0;
  display: flex;
  justify-content: space-evenly;
}

.icon-link {
  border-radius: 20px;
  width: 35%;
  border: 1px white solid;
  text-align: center;
  padding: 15px 0;
  text-decoration: none;
  font-size: 1.5rem;
  line-height: 100%;
  background: $colour-two;
  cursor: pointer;
  color: $colour-one;
  
  &:hover {
    background: lighten($colour-two, 10%);
  }

  &:active {
    background: darken($colour-two, 10%);
  }
  
  img {
    max-width: 25%;
    margin: 0 auto;
    display: block;
    opacity: .7;
  }
  
  span {
    width: 50%;
    display: inline-block;
    font-family: $font-one;
    font-weight: bold;
    text-align: center;
    position: relative;
    text-decoration: underline;  
  }
}

@include responsive-full-width {
  .icon-link {
    font-size: 1.2rem;

    span {
      width: 80%;
    }

    img {
      max-width: 40%;
    }
  }
}