@import '../Sass/responsive';

.booking-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 0 0 30px 0;
}

.form-group {
  margin: 10px 0 20px 0;
}

.form-group-label {
  display: block;
  margin: 0 0 5px 0;
}

.form-group-input {
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
}

.form-group-error {
  color: red;
}

button[type="submit"] {
  @include global-button-main;
}

.insta-embed-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding:0;
  justify-content: space-between;
  align-content: flex-start;

  li {
    width: 49%;
  }  
}

.pay-button-container {
  max-width: 300px;
  margin: 0 auto;
}

.pay-desc {
  text-align: center;

  em {
    display: inline-block;
    padding: 2px 4px;
    border-radius: 4px;
    font-style: normal;
    text-decoration: none;
    background: lighten($colour-two, 10%);
    background: linear-gradient(to right, $colour-two 0%, lighten($colour-two, 20%) 80%, lighten($colour-two, 10%) 100%);
  }
}

@include responsive-full-width {
  .booking-form {
    max-width: 100%;
    padding: 0 20px 30px;
  }

  .insta-embed-list {
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    
    li {
      width: 100%;
    }
  }
}