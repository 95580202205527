@import 'variables';

.hero {
  min-height: 300px;
  background-size: cover;
  background-position: center center;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: darken($colour-one, 3%);
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 0px;
    width: 50%;
    top: 50%;
    left: 50%;
    margin-left: -25%;
    box-shadow: 0 0 75px 30px black;
    z-index: 1;
  }
}

.hero h2 {
  width: 80%;
  font-size: 3rem;
  line-height: 100%;
  margin: 0 auto;
  font-family: $font-one;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.6);
  z-index: 2;
}

.hero p {
  padding: 10px 0 0 0;
  font-size: 1.5rem;
  width: 80%;
  margin: 0 auto;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.6);
  z-index: 2;
}
