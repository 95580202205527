html {
  height: 100%; }

body {
  margin: 0;
  min-height: 100%;
  padding-bottom: 45px;
  position: relative;
  box-sizing: border-box;
  background: #f7f7f7;
  font-family: -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.App {
  text-align: left;
  color: #333; }

main article {
  max-width: 800px;
  margin: 0 auto;
  background: white;
  padding: 0 20px 35px; }

@media only screen and (max-width: 800px) {
  main article {
    padding: 0; } }

footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 15px;
  height: 30px;
  background: #2a2d36;
  color: #a6abb9;
  text-align: center;
  font-size: 10px; }
  footer a {
    color: #a6abb9; }

header {
  background: #2a2d36;
  padding: 10px 20px;
  box-shadow: 0 5px 0 0 rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 5; }
  header .container {
    max-width: 800px;
    margin: 0 auto;
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex; }
  header h1 {
    color: white;
    margin: 0;
    width: 100%;
    font-family: "Arya", sans-serif;
    font-weight: 700;
    font-size: 31px;
    letter-spacing: 3px;
    text-transform: uppercase; }
    header h1 a {
      text-decoration: none;
      color: white; }
    header h1 span {
      color: #ffdf64; }

#nav-icon {
  width: 50px;
  height: 36px;
  position: relative;
  margin: 5px 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer; }

#nav-icon span {
  display: block;
  position: absolute;
  height: 5px;
  width: 100%;
  background: #FFF;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: .25s ease-in-out; }

#nav-icon span:nth-child(1) {
  top: 0px; }

#nav-icon span:nth-child(2), #nav-icon span:nth-child(3) {
  top: 15px; }

#nav-icon span:nth-child(4) {
  top: 30px; }

.App.open #nav-icon span:nth-child(1) {
  top: 15px;
  width: 0%;
  left: 50%; }

.App.open #nav-icon span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.App.open #nav-icon span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.App.open #nav-icon span:nth-child(4) {
  top: 15px;
  width: 0%;
  left: 50%; }

nav {
  display: none;
  background: #2a2d36;
  padding: 0;
  margin: 0;
  position: absolute;
  width: 100%;
  z-index: 5;
  top: 76px;
  left: 0; }

nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center; }

.App.open nav {
  display: block; }

nav a {
  color: white;
  text-decoration: none;
  display: block;
  padding: 20px; }

nav a:hover, nav a.active {
  background: rgba(0, 0, 0, 0.5); }

.text-block {
  padding: 10px 20px; }

.text-block-heading {
  font-family: "Arya", sans-serif;
  font-size: 2.4rem;
  margin: 0 0 10px 0;
  text-align: center;
  line-height: 120%; }

.text-block-content {
  font-size: 1.2rem; }

.text-block-content p {
  line-height: 140%;
  margin: 20px 0 20px 0; }

.text-block-content p:first-child {
  text-align: center;
  font-size: 1.5rem;
  color: #888;
  font-family: "Arya", sans-serif;
  width: 80%;
  margin: 0 auto;
  line-height: 120%; }

.text-block-content p:first-child::after {
  content: '';
  height: 4px;
  background: #EEE;
  width: 30%;
  display: block;
  margin: 20px auto;
  position: relative; }

.icon-grid {
  padding: 0 0 20px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly; }

.icon-link {
  border-radius: 20px;
  width: 35%;
  border: 1px white solid;
  text-align: center;
  padding: 15px 0;
  text-decoration: none;
  font-size: 1.5rem;
  line-height: 100%;
  background: #ffdf64;
  cursor: pointer;
  color: #2a2d36; }
  .icon-link:hover {
    background: #ffea97; }
  .icon-link:active {
    background: #ffd431; }
  .icon-link img {
    max-width: 25%;
    margin: 0 auto;
    display: block;
    opacity: .7; }
  .icon-link span {
    width: 50%;
    display: inline-block;
    font-family: "Arya", sans-serif;
    font-weight: bold;
    text-align: center;
    position: relative;
    text-decoration: underline; }

@media only screen and (max-width: 800px) {
  .icon-link {
    font-size: 1.2rem; }
    .icon-link span {
      width: 80%; }
    .icon-link img {
      max-width: 40%; } }

.loader {
  position: relative;
  margin: 0 auto 40px;
  padding: 30px 0;
  width: 60px; }

.loader:before {
  content: '';
  display: block;
  padding-top: 100%; }

.circular {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  height: 100%;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  stroke: #CCC; }

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round; }

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35; }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124; } }

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35; }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124; } }

.hero {
  min-height: 300px;
  background-size: cover;
  background-position: center center;
  text-align: center;
  color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: #23262d;
  position: relative; }
  .hero::after {
    content: '';
    display: block;
    position: absolute;
    height: 0px;
    width: 50%;
    top: 50%;
    left: 50%;
    margin-left: -25%;
    box-shadow: 0 0 75px 30px black;
    z-index: 1; }

.hero h2 {
  width: 80%;
  font-size: 3rem;
  line-height: 100%;
  margin: 0 auto;
  font-family: "Arya", sans-serif;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.6);
  z-index: 2; }

.hero p {
  padding: 10px 0 0 0;
  font-size: 1.5rem;
  width: 80%;
  margin: 0 auto;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.6);
  z-index: 2; }

.photo-image-container img {
  width: 100%; }

.caravan-detail-list {
  list-style: none;
  padding: 0;
  margin: 0; }
  .caravan-detail-list li {
    padding: 10px;
    border-bottom: 1px #EEE solid;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center; }
    .caravan-detail-list li:first-child {
      border-top: 1px #EEE solid; }
    .caravan-detail-list li .image-container {
      width: 35px;
      height: 35px;
      margin: 0 15px 0 0;
      text-align: center; }
    .caravan-detail-list li img {
      width: 100%;
      display: block;
      margin: 0 auto; }
    .caravan-detail-list li span {
      font-size: 1.2rem; }

.mapouter {
  position: relative;
  text-align: right;
  height: 500px;
  width: 100%; }

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 500px; }

.terms-page p {
  font-size: .9rem; }

.dates-list {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center; }
  .dates-list li {
    padding: 10px 0;
    margin: 0; }
    .dates-list li a {
      color: #00aad4;
      font-size: 1.1rem;
      text-decoration: none;
      border-bottom: 5px solid #eefcff; }
      .dates-list li a:hover {
        border-color: #a1ecff; }
    .dates-list li:hover em {
      -webkit-transform: translateX(15px);
              transform: translateX(15px); }
    .dates-list li em {
      transition: -webkit-transform .2s;
      transition: transform .2s;
      transition: transform .2s, -webkit-transform .2s;
      display: inline-block;
      padding: 2px 4px;
      margin: 0 0 0 15px;
      border-radius: 4px;
      font-style: normal;
      text-decoration: none;
      background: #ffea97;
      background: linear-gradient(to right, #ffdf64 0%, #fff4ca 80%, #ffea97 100%); }

section h2 {
  text-align: center; }

.contact-button {
  padding: 17px 32px;
  margin: 10px 0;
  border-radius: 7px;
  border: 0;
  background: #ffdf64;
  color: #2a2d36;
  letter-spacing: 1px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  outline: 0;
  text-decoration: none;
  margin: 10px 0 0 0;
  box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.09);
  text-shadow: 1px 1px 1px 0 white;
  text-shadow: -1px -1px #ffca00, 1px 1px #ffeda6;
  display: inline-block; }
  .contact-button:hover:enabled {
    background: #ffefb1; }
  .contact-button:active:enabled {
    background: #ffcf18; }
  .contact-button[disabled] {
    opacity: .2; }

.booking-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 0 0 30px 0; }

.form-group {
  margin: 10px 0 20px 0; }

.form-group-label {
  display: block;
  margin: 0 0 5px 0; }

.form-group-input {
  padding: 8px;
  width: 100%;
  box-sizing: border-box; }

.form-group-error {
  color: red; }

button[type="submit"] {
  padding: 17px 32px;
  margin: 10px 0;
  border-radius: 7px;
  border: 0;
  background: #ffdf64;
  color: #2a2d36;
  letter-spacing: 1px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  outline: 0;
  text-decoration: none;
  margin: 10px 0 0 0;
  box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.09);
  text-shadow: 1px 1px 1px 0 white;
  text-shadow: -1px -1px #ffca00, 1px 1px #ffeda6; }
  button[type="submit"]:hover:enabled {
    background: #ffefb1; }
  button[type="submit"]:active:enabled {
    background: #ffcf18; }
  button[type="submit"][disabled] {
    opacity: .2; }

.insta-embed-list {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-content: flex-start;
          align-content: flex-start; }
  .insta-embed-list li {
    width: 49%; }

.pay-button-container {
  max-width: 300px;
  margin: 0 auto; }

.pay-desc {
  text-align: center; }
  .pay-desc em {
    display: inline-block;
    padding: 2px 4px;
    border-radius: 4px;
    font-style: normal;
    text-decoration: none;
    background: #ffea97;
    background: linear-gradient(to right, #ffdf64 0%, #fff4ca 80%, #ffea97 100%); }

@media only screen and (max-width: 800px) {
  .booking-form {
    max-width: 100%;
    padding: 0 20px 30px; }
  .insta-embed-list {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center; }
    .insta-embed-list li {
      width: 100%; } }

.pay-details ul {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0;
  border: 1px #eeeeee solid;
  border-radius: 10px;
  overflow: hidden; }
  .pay-details ul li {
    padding: 15px 25px;
    border-bottom: 1px #eeeeee solid; }
    .pay-details ul li:last-child {
      border: 0; }
    .pay-details ul li.heading {
      background: #eeeeee;
      font-weight: bold; }
    .pay-details ul li span {
      font-weight: bold;
      display: inline-block;
      min-width: 130px; }

