@import 'variables';

.text-block {
  padding: 10px 20px;
}

.text-block-heading {
  font-family: $font-one;
  font-size: $font-size-one;
  margin: 0 0 10px 0;
  text-align: center;
  line-height: 120%;
}

.text-block-content {
  font-size: $font-size-two;
}

.text-block-content p {
  line-height: 140%;
  margin: 20px 0 20px 0;
}

.text-block-content p:first-child {
  text-align: center;
  font-size: $font-size-three;
  color: $colour-text-two;
  font-family: $font-one;
  width: 80%;
  margin: 0 auto;
  line-height: 120%;
}

.text-block-content p:first-child::after {
  content: '';
  height: 4px;
  background: $colour-three;
  width: 30%;
  display: block;
  margin: 20px auto;
  position: relative;
}
