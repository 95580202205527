.mapouter {
  position: relative;
  text-align:right;
  height:500px;
  width:100%;
}

.gmap_canvas {
  overflow:hidden;
  background:none!important;
  height:500px;
}