@import '../Sass/variables';

.dates-list {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
  
  li {
    padding: 10px 0;
    margin: 0;

    a {
      color: $colour-text-three;
      font-size: 1.1rem;
      text-decoration: none;
      border-bottom: 5px solid lighten($colour-text-three, 55%);

      &:hover {
        border-color: lighten($colour-text-three, 40%);
      }
    }

    &:hover em {
      transform: translateX(15px);
    }
    
    em {
      transition: transform .2s;
      display: inline-block;
      padding: 2px 4px;
      margin: 0 0 0 15px;
      border-radius: 4px;
      font-style: normal;
      text-decoration: none;
      background: lighten($colour-two, 10%);
      background: linear-gradient(to right, $colour-two 0%, lighten($colour-two, 20%) 80%, lighten($colour-two, 10%) 100%);
    }
  }
}

section h2 {
  text-align: center;
}

.contact-button {
  @include global-button-main {
    display: inline-block;
  }
}
