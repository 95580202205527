html {
  height: 100%; }

body {
  margin: 0;
  min-height: 100%;
  padding-bottom: 45px;
  position: relative;
  box-sizing: border-box;
  background: #f7f7f7;
  font-family: -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.App {
  text-align: left;
  color: #333; }

main article {
  max-width: 800px;
  margin: 0 auto;
  background: white;
  padding: 0 20px 35px; }

@media only screen and (max-width: 800px) {
  main article {
    padding: 0; } }

footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 15px;
  height: 30px;
  background: #2a2d36;
  color: #a6abb9;
  text-align: center;
  font-size: 10px; }
  footer a {
    color: #a6abb9; }
